.fc-event {
  font-size: 0.9rem;
  font-weight: 600;
  white-space: normal;
}
.fc-event-title-container {
  cursor: pointer;
}

.tooltip_box {
  position: relative;
  background: #555;
  direction: rtl;
  padding: 0.2rem;
}
.tooltip_box hr {
  margin-left: 0;
  margin-right: 0;
}
.tooltip_box:after,
.tooltip_box:before {
  right: 100%;
  top: 50%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.tooltip_box:after {
  border-color: rgba(85, 85, 85, 0);
  border-right-color: #555;
  border-width: 10px;
  margin-top: -10px;
}
.tooltip_box:before {
  border-color: rgba(0, 0, 0, 0);
  border-right-color: #000000;
  border-width: 13px;
  margin-top: -13px;
}

#chart .apexcharts-tooltip {
  color: #fff;
  transform: translateX(10px) translateY(10px);
  overflow: visible !important;
  white-space: normal !important;
}

#chart .apexcharts-tooltip span {
  padding: 5px 10px;
}
